import 'magnific-popup';

jQuery( document ).ready( function ( $ ) {
	// Social popup open
	$( '.share>li>a' ).on( 'click', function ( e ) {
		e.preventDefault();
		var link = $( this ).attr( 'href' );
		var w = 670;
		var h = 378;
		var y = window.outerHeight / 2 + window.screenY - (
			h / 2
		);
		var x = window.outerWidth / 2 + window.screenX - (
			w / 2
		);
		window.open( link, '_blank', 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' + w + ', height=' + h + ', top=' + y + ', left=' + x );
	} );

	// Pre-save
	let SMEPreSave = {
		state: null,
		spotify_user: null,
		url_vars: [],
		getURLVars: function ( callback, form_id ) {
			let hash;
			let hashes = window.location.href.slice( window.location.href.indexOf( '?' ) + 1 ).split( '&' );
			for ( let i = 0; i < hashes.length; i ++ ) {
				hash = hashes[i].split( '=' );
				this.url_vars.push( hash[0] );
				this.url_vars[hash[0]] = hash[1];
			}

			this.state = 'state' in this.url_vars ? this.url_vars['state'] : null;
			this.ae_member_id = 'ae_member_id' in this.url_vars ? this.url_vars['ae_member_id'] : null;
			this.spotify_user = 'spotify_user' in this.url_vars ? this.url_vars['spotify_user'] : null;
			this.deezer_user = 'deezer_user' in this.url_vars ? this.url_vars['deezer_user'] : null;
			this.message = 'message' in this.url_vars ? this.url_vars['message'] : null;
			if ( typeof callback === 'function' ) {
				callback( this.state, this.ae_member_id, this.spotify_user, this.deezer_user, this.message );
			}
		}
	};

	SMEPreSave.getURLVars( function ( state, ae_member_id, spotify_user, deezer_user, message ) {
		if ( state === 'success' || state === 'thank-you' ) {
			$.magnificPopup.open( {
				items: {
					src: '#presave-thanks',
					type: 'inline'
				}
			} );
		}
	} );

	// Newsletter
	$( '.newsletter>form' ).on( 'submit', function ( e ) {
		e.preventDefault();
		const data = $( this ).serialize();

		$.ajax( {
			type: 'POST',
			url: 'https://subs.sonymusicfans.com/submit',
			dataType: 'json',
			data: data,
			xhrFields: {
				withCredentials: false
			},
			success: function ( data ) {
				$( '.newsletter>form' ).html( '<div class="thanks">Thanks for signing up!</div>' );
			},
			error: function ( err ) {
				// Do things when submission has an error.
				alert( 'An error has occurred!' );
			}
		} );
	} );
} );